import { SOCIAL_MEDIA_URLS } from '@configs/socialMedia.config'
import { SocialMediaPlatform } from '@enums/SocialMediaPlatform'

export const formatResponse = res => res?.data?.data

export const onSocialMediaClick = (platform: SocialMediaPlatform, shareLinkWithText: string) => {
  const shareUrl = SOCIAL_MEDIA_URLS[platform]

  if (shareUrl) {
    window.open(`${shareUrl}${encodeURIComponent(shareLinkWithText)}`, '_blank')
  } else {
    console.error('Unsupported platform')
  }
}
export const stringInterpolate = (str = '', obj = {}) =>
  str.replace(/{([^{}]*)}/g, (rawString, key) =>
    typeof rawString === 'string' && typeof obj === 'object' ? obj[key] : rawString
  )

export const convertToEmbedUrl = watchUrl => {
  // Check if the URL is a valid YouTube watch URL
  const urlPattern = /^https:\/\/www\.youtube\.com\/watch\?v=([^&]+).*$/
  const match = watchUrl.match(urlPattern)

  if (match?.[1]) {
    const videoId = match[1]
    return `https://www.youtube.com/embed/${videoId}?controls=0`
  } else {
    return watchUrl // Return same if the input is not a valid YouTube watch URL
  }
}
