export const formatDuration = duration => {
  const minutes = Math.floor(duration / 60)
  const seconds = duration % 60
  return `${minutes}:${seconds.toString().padStart(2, '0')}`
}

export function myClickHandler() {
  if (this.isFullscreen()) {
    this.exitFullscreen()
  } else {
    this.requestFullscreen()
  }
}

export const onShareButton = (shareObject, showModal) => {
  if (navigator.share) {
    navigator
      .share(shareObject)
      .catch(error => console.log('Error sharing:', error))
  } else {
    showModal(true)
  }
}
