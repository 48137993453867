/* eslint-disable @typescript-eslint/no-floating-promises */

import React from 'react'
import { formatDuration } from '@utils/videoUtils'
import styles from './videocard.module.scss'

interface VideoCardProps {
  thumbnailSrc: string
  title: string
  views?: string
  duration?: string
  isMobile?: boolean
  id?: string
  setSelectedVideo: Function
  selectedVideoId?: string
  isVideoListPage?: boolean
}

const VideoCard = ({
  thumbnailSrc,
  title,
  views,
  duration,
  id,
  isMobile = false,
  selectedVideoId,
  setSelectedVideo,
  isVideoListPage = false,
}: VideoCardProps) => {
  const videoCardClick = () => {
    setSelectedVideo(id)
  }
  return (
    <div
      className={`${styles.cardContainerBox} ${isMobile && styles.isMobile} ${
        isVideoListPage && styles.isVideoListPage
      }`}
      onClick={() => videoCardClick()}>
      <div className={styles.imageContainer}>
        <img
          src={thumbnailSrc}
          alt="Video Thumbnail"
          className={`${styles.thumbnail} ${isMobile && styles.isMobile} ${isVideoListPage && styles.isVideoListPage}`}
        />
        {id !== selectedVideoId ? (
          <img
            className={`${styles.overlay} ${isMobile && styles.isMobile} ${isVideoListPage && styles.isVideoListPage}`}
            src="https://acko-cms.ackoassets.com/playbutton_6043ee29d7.png"
            alt="Play button"
          />
        ) : (
          <img className={styles.overlayPlaying} src="https://acko-cms.ackoassets.com/Video_Overlay_881056cfc3.svg" />
        )}
      </div>
      <div className={`${styles.content} ${isMobile && styles.isMobile} ${isVideoListPage && styles.isVideoListPage}`}>
        <div className={`${styles.title} ${isVideoListPage && styles.isVideoListPage}`}>{title}</div>
        <div className={`${styles.details} ${isVideoListPage && styles.isVideoListPage}`}>
          {(isVideoListPage || !isMobile) && (
            <>
              {(views ?? 0) > 0 && (
                <>
                  <span>{views} views</span>
                  <span className={styles.dotOrPipe}>{isMobile ? '.' : '|'}</span>
                </>
              )}
              <span className={styles.duration}>{formatDuration(duration)}</span>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default VideoCard
