/* eslint-disable @typescript-eslint/no-floating-promises */

import React from 'react'
import { convertToEmbedUrl } from './VideoPlayer.helpers'
import styles from './videoplayer.module.scss'

export const VideoJS = props => (
  <div className={` ${styles.container} ${props?.fullWidth && styles.fullWidth}`}>
    <iframe
      title={props?.videoDetails?.data?.title}
      allowFullScreen
      src={convertToEmbedUrl(props?.videoDetails?.data?.stream_data[0]?.link)}
    />
  </div>
)

export default React.memo(VideoJS)
