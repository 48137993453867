/* eslint-disable @typescript-eslint/no-floating-promises */

import React, { useRef } from 'react'
import Image from 'next/image'
import { onSocialMediaClick, stringInterpolate } from '@components/brandcomponents/VideoPlayer/VideoPlayer.helpers'
import { SocialMediaPlatform } from '@enums/SocialMediaPlatform'
import Modal from './sharemodal'
import {
  CLOSE_ICON,
  WHATSAPP_ICON,
  FACEBOOK_ICON,
  TELEGRAM_ICON,
  TWITTER_ICON,
  LINKEDIN_ICON,
  COPY_ICON,
} from './constants'

import styles from './modal.module.scss'

const ShareWidgetModal = ({ show = true, showModal, shareLink, shareLinkText }) => {
  const shareLinkContent = `${shareLinkText} {shareLink}` // Embed video link in share message, should be passed from backend

  const inputRef = useRef<any>(null)

  const handleCopyClick = () => {
    if (inputRef.current) {
      inputRef.current.select()
      navigator.clipboard
        .writeText(inputRef.current.value)
        .then(() => {
          console.log('Text copied to clipboard')
        })
        .catch(error => {
          console.error('Failed to copy text to clipboard:', error)
        })
    }
  }

  const onShareClick = (socialMediaFunc, socialMediaPlatform) => {
    const shareLink = inputRef.current.value
    const shareLinkWithText = stringInterpolate(shareLinkContent, { shareLink })
    socialMediaFunc(shareLinkWithText, socialMediaPlatform)
  }

  const modalContent = () => (
    <div>
      <div className={styles.modalHeader}>
        <h3 className={styles.modalTitle}>Share</h3>
        <Image
          src={CLOSE_ICON}
          alt="Cross Icon"
          width={24}
          height={24}
          className={styles.closeButton}
          onClick={() => {
            showModal(false)
          }}
        />
      </div>
      <div className={styles.shareModal}>
        <div className={styles.iconWrapper}>
          <Image
            src={WHATSAPP_ICON}
            alt="WhatsApp"
            width={59}
            height={72}
            className={styles.icon}
            onClick={() => onShareClick(onSocialMediaClick, SocialMediaPlatform.WhatsApp)}
          />
          <Image
            src={FACEBOOK_ICON}
            alt="Facebook"
            width={56}
            height={72}
            className={styles.icon}
            onClick={() => onShareClick(onSocialMediaClick, SocialMediaPlatform.Facebook)}
          />
          <Image
            src={TELEGRAM_ICON}
            alt="Telegram"
            width={53}
            height={72}
            className={styles.icon}
            onClick={() => onShareClick(onSocialMediaClick, SocialMediaPlatform.Telegram)}
          />
          <Image
            src={TWITTER_ICON}
            alt="Twitter"
            width={48}
            height={72}
            className={styles.icon}
            onClick={() => onShareClick(onSocialMediaClick, SocialMediaPlatform.Twitter)}
          />
          <Image
            src={LINKEDIN_ICON}
            alt="LinkedIn"
            width={48}
            height={72}
            className={styles.icon}
            onClick={() => onShareClick(onSocialMediaClick, SocialMediaPlatform.LinkedIn)}
          />
        </div>
        <div className={styles.separator} />
        <p className={styles.copyLinkText}>Copy Link</p>
        <div className={styles.inputWrapper}>
          <input type="text" ref={inputRef} value={shareLink} className={styles.input} disabled />
          <Image
            src={COPY_ICON}
            alt="Copy"
            width={24}
            height={24}
            className={styles.copyIcon}
            onClick={handleCopyClick}
          />
        </div>
      </div>
    </div>
  )

  return (
    <Modal isOpen={show} onClose={() => showModal(false)}>
      {modalContent()}
    </Modal>
  )
}

export default ShareWidgetModal
